import React from "react";
import Resume from "../components/resume/Resume";
import {PersonType} from "../model/PersonType";
import {siteMetaData} from "../siteMetaData";
import {PageProps} from "gatsby";

export default (props: PageProps) => {
  return (
    <Resume
      personType={PersonType.ThirdPersonSingular}
      language="de"
      href={siteMetaData.getUrlForPath(props.location.pathname)}/>
  )
}
